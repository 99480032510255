import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MainBody from './MainBody'
// import DownloadBox from './DownloadBox'
import legalAidObj from "./content/legal-aid.json"

function LegalAid () {
  const { t } = useTranslation()
  const classes = useStyles()
  const [chapter] = useState('')

  return (
    <>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>{t('Legal Aid in Hong Kong')}</Typography>
            <Grid container spacing={5}>
              <Grid item sm={12} md={6} dangerouslySetInnerHTML={{ __html: t("The Legal Aid Scheme is a service offered by the <a href='https://www.lad.gov.hk/eng/home/home.html' target='_blank'>Hong Kong Legal Aid Department</a> to help eligible applicants to pay for legal representation in civil or criminal proceedings.\n\nThe Legal Aid Department does not offer general legal advice service. Apart from private practices, there are a number of legal schemes and supplemental legal assistance services in Hong Kong if your legal aid application fails. Find out more in the FAQ below.") }} />
              <Grid item sm={12} md={6} dangerouslySetInnerHTML={{ __html: t("<strong>You may be eligible:</strong>\nIf you are involved in any case in a District Court, the Court of First Instance, the Court of Appeal and the Court of Final Appeal of Hong Kong, you could apply for legal aid even if you are not a Hong Kong citizen.\n\nIf you are charged with a serious crime and the magistrate court is considering whether to direct the case to the Court of First instance (Committal proceeding), you can also apply for legal aid.\n\nIn order to receive legal aid, you need to pass both the <strong>means test</strong> and <strong>merits test</strong>.") }} />
            </Grid>
          </CardContent>
        </Card>
        <MainBody
          GodObj={legalAidObj}
          changeChapter={chapter}
         />
      </Container>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 !important',
    "& a": {
      color: 'white',
    },
  },
  card: {
    background: '#897DCD',
    marginTop: '40px',
    padding: '60px 100px',
    color: '#FEFBFA',
    fontFamily: 'Chivo',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    }
  },
  title: {
    fontSize: '22px',
    lineHeight: 1.2,
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      // fontSize: '24px'
    }
  },
  container: {
    marginTop: '130px',
    fontSize: '18px',
    lineHeight: 1.4,
    [theme.breakpoints.down('xs')]: {
      marginTop: '90px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      fontSize: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1408px',
    }
  }
}))

export default LegalAid

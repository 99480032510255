import './App.css';
import './index.css';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  // useRouteMatch
} from "react-router-dom";
import Nav from './Nav'
import Banner from './Banner'
import HomeBanner from './HomeBanner'
import DownloadBox from './DownloadBox'
import DueProcess from './DueProcess'
import LegalAid from './LegalAid'
import Home from './Home'
import Footer from './Footer'
import {
  CssBaseline,
  Container
} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles'

function App() {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={Theme}>
      <Router>
        <CssBaseline />
        <Switch>
          <Route exact path="/">
            <Redirect to="/zh/home" />
          </Route>
          <Route exact path="/:lang/home">
            <div className={classes.headerBackgroundHome}>
              <Nav backgroundColor="#E45F5F" />
              <HomeBanner />
            </div>
            <Home />
          </Route>
          <Route exact path={'/:lang/legal-aid/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#8D81D3" />
              <Banner />
              <DownloadBox />
            </div>
            <Container maxWidth="xl">
              <LegalAid />
            </Container>
          </Route>
          <Route exact path={'/:lang/due-process/:section?'}>
            <div className={classes.headerBackgroundInner}>
              <Nav backgroundColor="#58B7B7" />
              <Banner />
              <DownloadBox />
            </div>
            <Container maxWidth="xl">
              <DueProcess />
            </Container>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </MuiThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  funnyCircle: {
    background: 'linear-gradient(30.85deg, #DC6161 10.09%, #F59999 82.65%)',
    opacity: 0.5,
    borderRadius: '50%',
    position: 'relative',
    [theme.breakpoints.down('sm')]:{
      width: '830px',
      left: '46%',
      top: '-106px',
      height: '830px'
    },
    [theme.breakpoints.up('md')]:{
      width: '1165px',
      height: '1247.11px',
      left: '46%',
      top: '-237.13px'
    }
  },
  funnyCircleContainer: {
    position: 'absolute',
    width: '100%',
    height: '550px',
    overflow: 'hidden'
  },
  headerBackground: {
    height: '550px',
    background: '#3B3C45',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  headerBackgroundHome: {
    background: '#E45F5F',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  headerBackgroundInner: {
    background: '#3B3C45',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  }
}))

const Theme = createMuiTheme({
  typography: {
    fontFamily: "Chivo, sans-serif"
  }
})

export default App;

import React, { useState } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  // Typography
} from '@material-ui/core'
// import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MainBody from './MainBody'
// import DownloadBox from './DownloadBox'
import dueProcessObj from './content/due-process.json'

const processes = [
  ['Police encounters', '/police.svg', 'police-encounters'],
  ['At the point of arrest', '/handcuffs.svg', 'at-the-point-of-arrest'],
  ['At the police station', '/police_station.svg', 'at-the-police-station'],
  ['At the Magistrates’ Courts', '/judge_hammer.svg', 'at-the-magistrates-courts'],
  ['Police mistreatment', '/mistreatment.svg', 'police-mistreatment']
];

function DueProcess () {
  const { t } = useTranslation()
  const classes = useStyles()
  const [chapter, setChapter] = useState('')

  return (
    <>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.title} dangerouslySetInnerHTML={{
              __html: t('Jump to the scenario you need:')
            }} />
            <Grid container justify="space-between" className={classes.cardContainer}>
              {
                processes.map(process => (
                  <Grid item xs={12} sm={12} md={12} lg={2} key={process[0]}>
                    <Card className={classes.scenario}>
                      <CardActionArea onClick={() => setChapter(process[2])}>
                        <img src={process[1]} alt={process[0]} />
                        <div dangerouslySetInnerHTML={{
                          __html: t(process[0])
                        }} />
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
          </CardContent>
        </Card>
        <MainBody
          GodObj={dueProcessObj}
          changeChapter={chapter}
        />
      </Container>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  scenario: {
    background: 'rgba(179, 221, 221, 0.3)',
    borderRadius: '5px',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: '9px',
    },
    '& button': {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 25px',
      height: '150px',
      "& > img": {
        position: 'absolute',
        bottom: '20px',
        right: '25px',
        alignSelf: 'flex-end',
        [theme.breakpoints.down('md')]: {
          bottom: '50%',
          transform: 'translateY(50%)',
        },
        [theme.breakpoints.down('sm')]: {
          height: '45px',
        },
      },
      "& > div": {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '130%',
        color: '#FEFBFA'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 25px 10px 30px',
      },
      [theme.breakpoints.down('md')]: {
        // '& > button': {
          flexDirection: 'row',
          alignItems: 'center',
          fontSize: '24px',
          height: 'inherit',
          minHeight: '95px',
          paddingRight: '90px',
        //}
      }
    }
  },
  cardContainer: {
    // marginTop: '15px',
    "& > div:before": {
      top: '45%',
      position: 'relative',
      content: '"__"',
      color: 'transparent',
      left: '-18%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    "& > div:not(:first-child):before": {
      borderBottom: '1px solid #83DDDD',
    },
  },
  cardContent: {
    padding: '0 !important',
  },
  card: {
    background: '#4DADAD',
    marginTop: '40px',
    padding: '40px 100px',
    color: '#FEFBFA',
    fontFamily: 'Chivo',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    }
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px',
    marginBottom: '0',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    }
  },
  container: {
    marginTop: '130px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      marginTop: '130px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1408px',
    }
  }
}))

export default DueProcess

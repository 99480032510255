import React from 'react'
import {
  Button,
  Container,
  Hidden
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'

const Footer = () => {
  const classes = useStyles()

  const handleShare = (method) => {
    if ( method === 'fb' ){
      window.open('https://www.facebook.com/civicsightasia/')
    } else if ( method === 'tw'){
      window.open('https://twitter.com/civicsightasia/')
    } else if ( method === 'ig' ){
      window.open('https://instagram.com/kyr_hongkong')
    }
  }

  return (
    <div className={classes.footer}>
      <Container className={classes.container}>
        <div className={classes.shareGroup}>
          <Hidden smDown>
            <div className={classes.textGroup}>
              <div>© 2021 Know Your Rights HK</div>
              <div><a href="https://civicsight.org/terms-conditions/" className={classes.link}>Terms & Conditions</a></div>
            </div>
          </Hidden>
          <Button className={classes.button} onClick={() => handleShare('ig')}><img src="/ig_white.svg" alt="IG share" /></Button>
          <Button className={classes.button} onClick={() => handleShare('fb')}><img src="/fb_white.svg" alt="Facebook share"/></Button>
          <Button className={classes.button} onClick={() => handleShare('tw')}><img src="/tw_white.svg" alt="Twitter share"/></Button>
        </div>
        <div className={classes.logoGroup}>
          <div>
            <div className={classes.engText}>Know Your Rights HK</div>
            <div className={classes.chiText}>人人有權</div>
          </div>
          <a className={classes.logoLeitner} href="http://www.leitnercenter.org/" target="_blank" rel="noreferrer"><img src="/leitner.png" alt="Leitner Center" /></a>
          <a className={classes.logoCivic} href="https://civicsight.org/" target="_blank" rel="noreferrer"><img src="/civic-sight.svg" alt="civic-sight 公民透視" /></a>
        </div>
        <Hidden mdUp>
          <div className={classes.textGroup}>
            <div>© 2021 Know Your Rights HK</div>
            <div><a href="https://civicsight.org/terms-conditions/" className={classes.link}>Terms & Conditions</a></div>
          </div>
        </Hidden>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  shareGroup:{
    display: 'flex',
    flexDirection: 'row'
  },
  logoGroup: {
    display: 'flex',
    flexDirection: 'row',
    "& > *": {
      padding: '0px 16px',
    },
    "& > *:not(:first-child)": {
      borderLeft: '1px solid rgba(59, 60, 69, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      "& > *": {
        padding: '10px 8px',
      },
      "& > *:not(:first-child)": {
       borderLeft: 'unset'
      },
    }
  },
  logoLeitner: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxHeight: '44px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '44px',
      },
    }
  },
  logoCivic: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxHeight: '30px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '35px',
      },
    }
  },
  textGroup: {
    display: 'flex',
    padding: '15px 0px',
    marginRight: '14px',
    "& > *": {
      padding: '0px 8px',
      fontSize: '13px',
      lineHeight: '15px',
      color: '#8C8A8F'
    },
    "& > *:not(:first-child)":{
      borderLeft: '1px solid #8C8A8F'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      "& > *:not(:first-child)":{
        borderLeft: 'unset'
      },
    }
  },
  chiText: {
    fontWeight: 900,
    fontSize: '17px',
    lineHeight: '24px',
    letterSpacing: '0.2em',
    color: '#3B3C45',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    }
  },
  engText: {
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#3B3C45',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  button: {
    minWidth: '42px'
  },
  footer: {
    minHeight: '100px',
    background: 'rgba(140, 138, 143, 0.1)',
    padding: '44px 10px 25px',
  },
  link: {
    // textDecoration: 'none',
    color: '#8C8A8F',
  }
}))

export default Footer

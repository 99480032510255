import React from 'react'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// import MainBody from './MainBody'
// import homeObj from "./content/home.json"

function Home () {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Grid container spacing={0}>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/due-process`} className={classes.link}>
            <Card className={[classes.card, classes.dueProcess].join(' ')}>
              <span />
              <CardContent>
                <h2 className={classes.title}>{t('Due Process')}</h2>
                <div className={classes.icon}><img src="/icon-due.svg" alt="Due Process" /></div>
                <Typography className={classes.description}>{t('What is your experience with police encounters in Hong Kong? Are you aware of your rights in a search or an arrest? Has someone you care about been charged with an offence? \n\nDue process is a requirement on authorities to guarantee protections of legal rights of the individuals, and the proper application of law. \n\nYou have rights throughout the process. Understand them, exercise them, and know what your options are if you feel that your civil liberties and fundamental rights are at risk.')}</Typography>
                <div className={classes.cta}>
                  <Typography className={classes.linkText}>{t('Due Process')}</Typography>
                  <span className={classes.arrow}><img src='/arrow-teal.svg' alt='Due Process' /></span>
                </div>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item sm={6} md={6}>
          <Link to={`/${i18n.language}/legal-aid`} className={classes.link}>
            <Card className={[classes.card, classes.legalAid].join(' ')}>
              <span />
              <CardContent>
                <h3 className={classes.title}>{t('Legal Aid')}</h3>
                <div className={classes.icon}><img src="/icon-legal.svg" alt="Legal Aid" /></div>
                <Typography className={classes.description}>{t('Are you in need of legal assistance in Hong Kong? You have the right to legal advice and representation, and this right shouldn’t be hampered by costs. \n\nAccess to justice is more than protecting individual interests in legal proceedings. Our society depends on it - an accessible legal system enables the public to trust the courts to administer law in a fair and just manner.\n\nIn reality, navigating the judicial system can be a daunting experience, and seeking expert legal advice and engaging legal representation can be expensive. If you don’t have the means, there are various platforms and schemes in Hong Kong that may be available to you. Find out which one is right for you.')}</Typography>
                <div className={classes.cta}>
                  <Typography className={classes.linkText}>{t('Legal Aid')}</Typography>
                  <span className={classes.arrow}><img src='/arrow-purple.svg' alt='Due Process' /></span>
                </div>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    background: '#897DCD',
    marginTop: '0',
    padding: '115px 105px 105px',
    color: '#FEFBFA',
    fontFamily: 'Chivo',
    whiteSpace: 'pre-line',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px 30px',
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& > span': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '72%',
      height: '10px',
      background: '#4DADAD',
      transform: 'translateX(-50%)',
      transition: 'width 0.6s ease-in-out',
    }
  },
  icon: {
    maxWidth: '105px',
    margin: '40px 0',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  description: {
    textAlign: 'center',
    margin: '30px 0',
    fontSize: '18px',
    lineHeight: 1.5,
    transition: 'color 0.8s',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
      fontSize: '14px',
    },
  },
  cta: {
    margin: '30px 0',
    textTransform: 'uppercase',
  },
  linkText: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    maxWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'all 0.8s ease-in-out',
  },
  arrow: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0 40px',
    lineHeight: 0.8,
  },
  dueProcess: {
    background: '#3B3C45',
    '& $linkText': {
      color: '#4DADAD'
    }
  },
  legalAid: {
    background: '#525560',
    '& > span': {
      background: '#857BC6',
    },
    '& $linkText': {
      color: '#857BC6'
    }
  },
  title: {
    fontWeight: 900,
    fontSize: '36px',
    lineHeight: 1.2,
    margin: 0,
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  container: {
    maxWidth: 'inherit',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      marginTop: '0'
    },
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    height: '100%',
    '& > div': {
      height: '100%',
      transition: 'all 0.3s',
    },
    '&:hover': {
      '& > div > span': {
        width: '100%',
      },
      '& $dueProcess': {
        background: '#494B55',
        '& $description': {
          color: '#B3DDDD'
        }
      },
      '& $legalAid': {
        background: '#5E616E',
        '& $description': {
          color: '#E0DEF2'
        }
      },
      '& $linkText': {
        maxWidth: '200px',
      },
    }
  }
}))

export default Home

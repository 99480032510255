import React from 'react'
import {
  Card,
  CardActionArea,
  Button,
  Container,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination]);

function DownloadBox () {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Container className={classes.inner}>
        <div className={classes.headerText}><span className={classes.text}>{t('Downloads')}</span><span className="swiper-pagination" /></div>
        <div className={classes.downloadGroup}>
          <Switch>
            <Route path='/:lang/due-process'>
              <Swiper
                spaceBetween={15}
                slidesPerView='auto'
                freeMode={true}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                      <div className={classes.image}>
                        <img src={'/dl-flow-chart-due-process.png'} alt={t("Flow Chart - Due Process")} />
                      </div>
                      <div className={classes.content}>
                        <div>
                          <h5 dangerouslySetInnerHTML={{
                            __html: t("Flow Chart - Due Process")
                          }} />
                          <p dangerouslySetInnerHTML={{
                            __html: t("An outline of possible scenarios from the initial encounter with the police to the first court appearance.")
                          }} />
                        </div>
                        <Button className={[classes.dlButton, 'green'].join(' ')} variant="contained" href="/pdfs/KYR_Flowchart_DueProcess.pdf" target="_blank">
                          {t("Download")}
                        </Button>
                      </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                      <div className={classes.image}>
                        <img src={'/dl-dos-and-donts-wallet.png'} alt={t("Dos and Don'ts - Print Out")} />
                      </div>
                      <div className={classes.content}>
                        <div>
                          <h5 dangerouslySetInnerHTML={{
                            __html: t("Dos and Don'ts - Print Out")
                          }} />
                          <p dangerouslySetInnerHTML={{
                            __html: t("Print this out and keep it in your wallet.")
                          }} />
                        </div>
                        <Button className={[classes.dlButton, 'green'].join(' ')} variant="contained" href="/pdfs/KYR%20-%20Wallet%20Prints.pdf" target="_blank">
                          {t("Download")}
                        </Button>
                      </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                    <div className={classes.image}>
                      <img src={'/dl-due-process.png'} alt={t("Print This Page")} />
                    </div>
                    <div className={classes.content}>
                      <div>
                        <h5 dangerouslySetInnerHTML={{
                          __html: t("Print This Page")
                        }} />
                        <p dangerouslySetInnerHTML={{
                          __html: t("Download a copy of this page in full, covering all the topics below.")
                        }} />
                      </div>
                      <Button className={[classes.dlButton, 'green'].join(' ')} variant="contained" href="/pdfs/KYR%20-%20Full%20PDF%20-%20Due%20Process.pdf" target="_blank">
                        {t("Download")}
                      </Button>
                    </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </Route>
            <Route path='/:lang/legal-aid'>
              <Swiper
                spaceBetween={15}
                slidesPerView='auto'
                freeMode={true}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                      <div className={classes.image}>
                        <img src={'/dl-flow-chart-legal-aid-civil.png'} alt={t("Flow Chart - Legal Aid - Civil")} />
                      </div>
                      <div className={classes.content}>
                        <div>
                          <h5 dangerouslySetInnerHTML={{
                            __html: t("Flow Chart - Legal Aid")
                          }} />
                          <p dangerouslySetInnerHTML={{
                            __html: t("A step-by-step guide on applying for legal aid in Hong Kong.")
                          }} />
                        </div>
                        <Button className={[classes.dlButton, 'purple'].join(' ')} variant="contained" href="/pdfs/KYR_Flowchart_LegalAid_Civil.pdf" target="_blank">
                          {t("Download")}
                        </Button>
                      </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                      <div className={classes.image}>
                        <img src={'/dl-flow-chart-legal-aid.png'} alt={t("Flow Chart - Legal Aid - Criminal")} />
                      </div>
                      <div className={classes.content}>
                        <div>
                          <h5 dangerouslySetInnerHTML={{
                            __html: t("Flow Chart - Legal Aid")
                          }} />
                          <p dangerouslySetInnerHTML={{
                            __html: t("A step-by-step guide on applying for legal aid in Hong Kong.")
                          }} />
                        </div>
                        <Button className={[classes.dlButton, 'purple'].join(' ')} variant="contained" href="/pdfs/KYR_Flowchart_LegalAid_Criminal.pdf" target="_blank">
                          {t("Download")}
                        </Button>
                      </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.actionArea}>
                    <div className={classes.image}>
                      <img src={'/dl-legal-aid.png'} alt={t("Print This Page")} />
                    </div>
                    <div className={classes.content}>
                      <div>
                        <h5 dangerouslySetInnerHTML={{
                          __html: t("Print This Page")
                        }} />
                        <p dangerouslySetInnerHTML={{
                          __html: t("Download a copy of this page in full, covering all the topics below.")
                        }} />
                      </div>
                      <Button className={[classes.dlButton, 'purple'].join(' ')} variant="contained" href="/pdfs/KYR%20-%20Full%20PDF%20-%20Legal%20Aid.pdf" target="_blank">
                        {t("Download")}
                      </Button>
                    </div>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </Route>
          </Switch>
        </div>
      </Container>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    // top: '467px',
    // left: '55%',
    width: '100%',
    // minHeight: '165px',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // boxShadow: '4px 4px 25px rgba(0,0,0,0.15)',
    marginBottom: '-90px',
    paddingBottom: '30px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'normal',
      marginBottom: '-70px',
      paddingBottom: '30px',
    }
  },
  inner: {
    width: '100%',
    maxWidth: '1280px',
    // padding: '0 24px',
    // margin: '0 auto',
    overflow: 'visible',
  },
  downloadGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '15px',
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignSelf: 'center',
      width: '100%'
    },
    '& .swiper-container': {
      // overflow: 'visible',
      width: '100%',
      overflow: 'visible',
    },
    '& .swiper-slide': {
      width: '440px',
      [theme.breakpoints.down('xs')]: {
        width: '212px',
      },
    },
  },
  headerText: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#FEFBFA',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
    '& .swiper-pagination-bullet': {
      marginRight: '10px',
      width: '7px',
      height: '7px',
      background: '#FEFBFA',
      [theme.breakpoints.down('xs')]: {
        width: '5px',
        height: '5px',
      },
    },
  },
  text: {
    marginRight: '20px',
    opacity: 0.5,
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]:{
      flexDirection: 'column',
    },
  },
  image: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(140, 138, 143, 0.05)',
    "& > img": {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    [theme.breakpoints.down('xs')]:{
      width: '100%',
      height: '136px',
    },
  },
  content: {
    boxSizing: 'border-box',
    width: '70%',
    height: '100%',
    padding: '25px 25px 20px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]:{
      width: '100%',
      height: '200px',
      padding: '15px 20px',
    },
    "& h5": {
      color: '#3B3C45',
      fontSize: '18px',
      lineHeight: '21px',
      //fontWeight: 'bold',
      margin: '0',
      [theme.breakpoints.down('xs')]:{
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    "& p": {
      color: '#8C8A8F',
      fontSize: '12px',
      lineHeight: 1.4,
      [theme.breakpoints.down('xs')]:{
        marginTop: '10px',
      },
    },
  },
  dlButton: {
    alignSelf: 'flex-end',
    fontSize: '15px',
    lineHeight: 1.2,
    color: 'white',
    textTransform: 'none',
    borderRadius: '20px',
    boxShadow: 'none',
    padding: '10px 24px',
    "&.green": {
      backgroundColor: '#58B7B7',
    },
    "&.purple": {
      backgroundColor: '#8D81D3',
    },
  },
  card: {
    // minWidth: '440px',
    width: '100%',
    minHeight: '175px',
    display: 'flex',
    background: '#FEFBFA',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]:{
      // width: 'unset',
      '&:first-child': {
        // marginBottom: '15px'
      },
      minHeight: '311px',
    }
  }
}))

export default DownloadBox

import React from "react";
import {
  Container,
  Typography
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import {
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next'

function Banner(){
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div>
      <Container>
        <div className={classes.containerStyles}>
          <Switch>
            <Route path='/:lang/due-process'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('Due Process')}</Typography>
                <div className={classes.icon}><img src="/icon-due.svg" alt={t('Due Process')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Understand your legal rights and obligations in police encounters and while navigating the criminal justice system in Hong Kong. Know what to expect and act in your best interests if you are stopped, searched, arrested or charged.') }} />
            </Route>
            <Route path='/:lang/legal-aid'>
              <div className={classes.textBox}>
                <Typography className={classes.engText}>{t('Legal aid')}</Typography>
                <div className={classes.icon}><img src="/icon-legal.svg" alt={t('Legal aid')} /></div>
              </div>
              <Typography className={classes.descText} dangerouslySetInnerHTML={{ __html: t('Everything you need to know about getting free or subsidised legal advice, representation or other forms of legal assistance in Hong Kong.') }} />
            </Route>
          </Switch>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  textBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      // paddingTop: '90px',
      width: '100%',
    }
  },
  engText: {
    fontFamily: 'Chivo',
    fontWeight: 900,
    fontSize: '50px',
    lineHeight: 1.2,
    color: '#FEFBFA',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      width: '100%',
    }
  },
  descText: {
    width: '50%',
    // fontFamily: "'Noto Sans TC', sans-serif",
    fontFamily: 'Chivo',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '1.4',
    letterSpacing: '-0.01em',
    color: '#FEFBFA',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      width: '100%',
      marginTop: '40px',
    }
  },
  icon: {
    width: '85px',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
    }
  },
  containerStyles: {
    display: 'flex',
    justifyContent: 'space-around',
    overflow: 'hidden',
    padding: '120px 0 90px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '70px 0 45px',
    }
  }
}))

export default Banner;
